class DietPopup {

  constructor() {
    this.openElement;
    this.listener();
  }

  listener() {

    document.querySelector('body').addEventListener('click', ev => {
      // Sjekk om elementet som klikkes er et allergen:
      if (ev.target.classList.contains('allergen') && ev.target.firstElementChild) {
        if (this.openElement && this.openElement === ev.target.firstElementChild) {
          this.openElement.classList.add('allergenName--hidden');
          this.openElement = null;
          return
        }
        if (this.openElement) {
          this.openElement.classList.add('allergenName--hidden');
        }
        ev.target.firstElementChild.classList.toggle('allergenName--hidden');
        this.openElement = ev.target.firstElementChild;

      } else {
        if (this.openElement) {
          this.openElement.classList.add('allergenName--hidden');
          this.openElement = null;
        }
      }

    });
  }
}

export default DietPopup;