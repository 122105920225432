class HideMessageBox {
  constructor() {
    this.messageBox = document.querySelector('#messagebox');
    this.currentDate;
    this.events();
  }

  events() {
    document.addEventListener('DOMContentLoaded', () => {
      const seen = localStorage.getItem('boxseen');
      if (this.messageBox) {
        if (seen) {
          this.messageBox.style.display = "none";
        } else {
          localStorage.setItem('boxseen', true);
          this.currentDate = new Date();
        }

        if (this.currentDate && this.currentDate.getDate() !== new Date().getDate()) {
          localStorage.clear();
        }
      }

    });
  }
}

export default HideMessageBox;