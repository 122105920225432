class CameFromParentPage {
  constructor() {
    this.parentPage = "https://sj.togservice.no/";
    this.modal = document.querySelector('#referrerbox');
    this.init();
  }
  init() {
    let prevPage = document.referrer;
    if (prevPage === this.parentPage) {
      this.modal.style.display = "block";
    }
  }
}

export default CameFromParentPage;